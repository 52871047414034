<template>
    <div class="quick-action-snack-bar" :class="{'visibility-enabled': !$vuetify.breakpoint.xs}">
        <notification
            v-model="value"
            v-if="value"
            type="quick-actions"
            @close="close"
        >
            <template v-slot:condensedActions>
                <hb-menu
                    options
                    options-hover-type="quick-actions"
                >   
                    <v-list class="hb-menu-list-quick-actions">
                        <v-list-item>
                            <v-list-item-icon class="mr-1">
                                <hb-icon :color="`#101318`" class="ml-0 mr-0 d-flex align-start" :mdi-code="`mdi-flash-circle`"></hb-icon> <!-- Prepend icon -->
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title><strong>Quick Actions</strong></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- Divider -->
                        <v-divider></v-divider>
                        <v-list-item v-for="(item, index) in condensedActionItems" :ripple="false" @click="click(item)">
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </hb-menu>
            </template>
            <template v-slot:actions>
                <hb-btn v-for="(item, index) in actionItems" small class="ml-2" :color="item.payload == 'undoCloseOfDay' ? 'secondary' : 'primary'" @click="click(item)">{{ item.title }}</hb-btn>
            </template>
        </notification>
    </div>
</template>
<script>
    import notification from '../dashboard/widgets/components/Notification.vue'
    import { mapGetters, mapActions } from 'vuex';
    import { EventBus } from '../../EventBus.js'; 
import Vuetify from 'vuetify/lib';
    export default {
        data() {
            return {
                condenseQuickActions: false,
                sliceItem: 2,
                mBreakpoint: 510
            }
        },
        components:{ 
            notification
        },
        computed:{
            actionItems() {
                // Check if the screen width is less than 505
                if (this.$vuetify.breakpoint.width < this.mBreakpoint) {
                    // Return the last two items if the condition is true
                    return this.actions.slice(0, this.sliceItem);
                } else {
                    // Otherwise, return the full array or some other slice
                    return this.actions;
                }
            },
            condensedActionItems() {
                // Check if the screen width is less than 505
                if (this.$vuetify.breakpoint.width < this.mBreakpoint) {
                    // Return the last two items if the condition is true
                    return this.actions.slice(this.sliceItem);
                }
            },
            ...mapGetters({
                label: 'snackbarStore/label',
                actions: 'snackbarStore/actions',
                absolute: 'snackbarStore/absolute',
                bottom: 'snackbarStore/bottom',
                color: 'snackbarStore/color',
                left: 'snackbarStore/left',
                multiLine: 'snackbarStore/multiLine',
                right: 'snackbarStore/right',
                timeout: 'snackbarStore/timeout',
                top: 'snackbarStore/top',
                value: 'snackbarStore/value',
                vertical: 'snackbarStore/vertical',
                simple: 'snackbarStore/simple'
            })
        },
        methods: {
            ...mapActions({
                snackbarSetValue: 'snackbarStore/setValue'
            }),
            close(){
                this.$emit('close');
                this.snackbarSetValue(false);
            },
            click(item){
                EventBus.$emit(item.command, item.payload);
            }
        },
        mounted() {
            if(this.$vuetify.breakpoint.xs) {
                let snackBarElement = document.querySelector(`.quick-action-snack-bar`);
                setTimeout(() => {
                    snackBarElement.classList.add('visibility-enabled');
                }, 500)
            }
        }
    }
</script>
<style scoped lang="scss">
.hb-menu-list-quick-actions {
    width: 300px;
}
.quick-action-snack-bar {
    visibility: hidden;
}
.quick-action-snack-bar.visibility-enabled {
    visibility: visible;
}
</style>
