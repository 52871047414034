<template>
    <v-card>
        <v-card-subtitle>
            <span class="icon"></span> Open Maintenance Requests
            <button @click="deleteDashboard()" class="w-button btn-sm tertiary-btn delete-button" v-show="canDelete">
                <span class="icon"></span>
            </button>
        </v-card-subtitle>
        <v-card-text>
            <div class="chart-holder">
                <div class="chart-wrapper">
                    <canvas id="maintenanceRequestCharts"></canvas>
                </div>
                <div class="chart-summary">
                    <h2>{{ requestSum }}</h2>
                    <h6>Maintenance Requests</h6>
                </div>
                <div class="chart-legend">
                    <router-link tag="div" class="legend-row" to="/maintenance-requests?type=standard">
                        <span class="legend-row-label">
                            <span class="icon status status_standard" style="font-weight: 800" >Standard</span>
                        </span>
                        <span  class="standard"> {{requests.Standard}} </span>
                    </router-link>

                    <router-link tag="div" class="legend-row" to="/maintenance-requests?type=urgent">
                        <span class="legend-row-label">
                            <span class="icon status status_urgent" style="font-weight: 800" >Urgent</span>
                        </span>
                        <span class="urgent"> {{requests.Urgent}} </span>
                    </router-link>

                    <router-link tag="div" class="legend-row" to="/maintenance-requests?type=emergency">
                        <span class="icon  status status_emergency" style="font-weight: 800" >Emergency</span>
                        <span class="emergency"> {{requests.Emergency}} </span>
                    </router-link>
                </div>
            </div>
        </v-card-text>
        <router-link tag="div" class="row maintenance-panel" to="/maintenance-requests" v-if="false">
            <div class="dashboard-panel-row-1">
                <h4 class="dashboard-panel-header"><span class="icon"></span> &nbsp;Open Maintenance Requests</h4>
                <div id="maintenance-chart-holder">
                    <canvas id="maintenanceRequestCharts"></canvas>
                    <div class="chart-summary">
                        <h2>{{ requestSum }}</h2>
                        <h6>Maintenance Requests</h6>
                    </div>
                </div>
                <div id="maintenance-chart-legend">
                    <router-link tag="div" class="legend-row" to="/maintenance-requests?type=standard">
                        <span class="legend-row-label">
                            <span class="icon status status_standard" style="font-weight: 800" >Standard</span>

                        </span>
                        <span  class="standard"> {{requests.Standard}} </span>
                    </router-link>

                    <router-link tag="div" class="legend-row" to="/maintenance-requests?type=urgent">
                         <span class="legend-row-label">
                             <span class="icon status status_urgent" style="font-weight: 800" >Urgent</span>
                         </span>
                            <span class="urgent"> {{requests.Urgent}} </span>
                    </router-link>


                    <router-link tag="div" class="legend-row" to="/maintenance-requests?type=emergency">
                        <span class="icon  status status_emergency" style="font-weight: 800" >Emergency</span>
                        <span class="emergency"> {{requests.Emergency}} </span>
                    </router-link>
                </div>


            </div>
        </router-link>
    </v-card>
</template>
<script type="text/babel">


    import api from '../../assets/api.js';
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import moment from 'moment';

    export default {
        name: 'MaintenanceRequests',
        data: function(){
            return {
                chartDiv: {},
                chart:{},
                requests:{
                    Standard: 0,
                    Urgent: 0,
                    Emergency: 0
                },
                pid: '',
            }
        },
        mounted(){
            this.chartDiv = document.getElementById("maintenanceRequestCharts");
            this.pid = this.property_id;
            this.fetchData();
            
        },
        computed:{
            requestSum(){
                var _this = this;
               return  Object.keys(this.requests).reduce(function(a, b){
                   return a + _this.requests[b];
               }, 0)
            }
        },
        components:{
            Loader,
            Status
        },
        filters:{
        },
        methods:{
            deleteDashboard(){
                api.delete(this, api.DASHBOARD, this.dashboard.id).then(r => {
                    this.$parent.$emit('HBGet:Dashboards'); //this.fetchDashboards();
                });
            },
            fetchData(){
                var _this= this;
                api.get(this, api.DASHBOARD_MAINTENANCE_SUMMARY, {property_id: this.pid}).then(r => {
                    r.maintenance.forEach(function(m){
                        _this.requests[m.severity] = m.count;
                    });
                }).then(this.loadChart.bind(this));
            },
            loadChart(){
                var _this = this;
                var labels = Object.keys(this.requests).map(function(key, index) {
                    return key;
                });

                var data = Object.keys(this.requests).map(function(key, index) {
                    return _this.requests[key];
                });

                if(this.chart.labels) {
                    this.chart.data.datasets[0].data = data;
                    this.chart.update();
                    return;
                }


                this.chart = new Chart(this.chartDiv, {
                    type: 'doughnut',
                    data: {
                        labels:{
                            display:false
                        },
                        datasets: [{
                            label: '# of Votes',
                            data: data,
                            backgroundColor: [
                                'rgba(2, 136, 209, 1)',
                                'rgba(249, 168, 35, 1)',
                                'rgba(198, 40, 40, 1)'

                            ],
                            borderColor: [
                                'rgba(2, 136, 209, 1)',
                                'rgba(249, 168, 35, 1)',
                                'rgba(198, 40, 40, 1)'
                            ],
                            hoverBackgroundColor: [
                                'rgba(2, 136, 209, 1)',
                                'rgba(249, 168, 35, 1)',
                                'rgba(198, 40, 40, 1)'
                            ],
                            borderWidth: 1
                        }]
                    },
                    options:{
                        cutout: 93,
                        plugins: {
                            legend: {
                                display: false
                            },
                            tooltip:{
                                enabled: false
                            }
                        }
                    }
                });

            }
        },
        props:['property_id', 'canDelete', 'property', 'dashboard'],
        watch:{
            property_id:function (property_id, old_id){
                this.requests = {
                    Standard: 0,
                    Urgent: 0,
                    Emergency: 0
                };
                this.pid = property_id;
                this.fetchData();
            }
        }

    }
</script>
<style lang="scss" scoped>
    .chart-holder {
        position: relative;

        .chart-wrapper {
            width: 200px;
            height: 200px;
            margin: 40px auto;
            position: relative;
            max-width: 100%;
        }

        .chart-summary {
            position: absolute;
            top: 0;
            width: 100%;
            padding-top: 60px;
            z-index: 0;
            text-align: center;
            font-weight: 800;

            h2 {
                font-size: 52px;
                line-height: 52px;
                color: #586266;
                border: none;
                padding: 0;
                margin: 0;
            }

            h6{
                font-size: 12px;
                line-height: 18px;
                text-transform: uppercase;
                margin: 0;
                color: #586266;
            }
        }

        .chart-legend{
            width: 275px;
            max-width: 100%;
            padding: 0 10px;
            margin: 20px auto;
            color: #586266;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
        }
    }

</style>
