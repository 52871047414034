<template>
    <v-card>
        <v-card-subtitle>
            <i class="fa fa-envelope"></i> New Leads
            <button @click="deleteDashboard()" class="w-button btn-sm tertiary-btn delete-button" v-show="canDelete">
                <span class="icon"></span>
            </button>
        </v-card-subtitle>
        <v-card-text>
            <router-link tag="div" id="unread-count" to="/leads?search=&status=%5B%22New%22,%22Not%20Contacted%22,%22Active%22,%22Prospect%22%5D&source=%5B%5D&property_id=%5B%5D&category_id=%5B%5D&columns=name,address,category_name,number,created,status,email,phone,source,status,opened&sort=%7B%22field%22%3A%22created%22,%22dir%22%3A%22DESC%22%7D&page=1&limit">
                <span style="cursor:pointer">
                    <h2>{{ count }}</h2>
                    <h6>New Lead<span v-show="count != 1">s</span></h6>
                </span>
            </router-link>
            <router-link tag="div" class="horizontal-form-row lined clickable" v-for="l in leads" :key="l.id" :to="'/leads?id='+l.Contact.id">
                <div class="horizontal-form-label">
                    <strong>{{l.Contact.first}} {{l.Contact.last}}</strong><br />
                    {{l.source}}
                    {{l.created | formatDate}}
                </div>

                <div class="horizontal-input-container">
                    <div class="horizontal-form-text">
                        {{l.note}}<br />
                        <em>{{l.content}}</em>
                    </div>
                </div>
            </router-link>
        </v-card-text>
    </v-card>
</template>
<script type="text/babel">
    import api from '../../assets/api.js';
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import { EventBus } from '../../EventBus.js';
    import SlideOutWindow from '../includes/SlideOutWindow.vue';
    import moment from 'moment';
    export default {
        name: 'UnreadCount',
        data: function(){
            return {
                leads: [],
                pid: '',
                showWindow: false
            }
        },
        mounted(){
            this.pid = this.property_id;
            this.fetchData();
        },
        created(){
            EventBus.$on('lead_saved', this.fetchData);
        },
        destroyed(){
           EventBus.$off('lead_saved', this.fetchData);
        },
        components:{
            Loader,
            Status
        },
        computed:{
            count(){
                if(!this.leads) return 0;
                return this.leads.length;
            }
        },
        filters:{
            formatDate(date){
                return moment(date).format('MM/DD/YYYY');
            }
        },
        methods:{
            deleteDashboard(){
                api.delete(this, api.DASHBOARD, this.dashboard.id).then(r => {
                    this.$parent.$emit('HBGet:Dashboards'); //this.fetchDashboards();
                });
            },
            fetchData(){
                this.startLoading(this.$options.name);
                api.get(this, api.DASHBOARD_LEADS, {property_id: this.property_id}).then(r => {
                    this.leads = r.leads;
                });
            }
        },
        watch:{
            property_id: function (){
                this.leads = [];
                this.fetchData();
            }
        },
        props:['property_id', 'canDelete', 'property', 'dashboard']

    }
</script>
<style scoped>
    h4{
        margin: 0 0 20px 0;
    }
    #unread-count {
        width: 200px;
        height: 75px;
        margin: 0px auto 20px;
        position: relative;

    }
    #unread-count  h2 {
        font-size: 52px;
        line-height: 52px;
        color: #00b2ce;
        font-weight: 800;
        text-align: center;
        border: none;
        padding: 0;
        margin: 0;
    }

    #unread-count  h6{
        font-size: 14px;
        line-height: 18px;
        text-transform: uppercase;
        margin: 0;
        font-weight: 800;
        color: #586266;
        text-align: center;
        padding: 0 20px;
        border: none;
    }

    .dashboard-panel-row-1{
        height: 415px;
    }
    .message-content {
        height: 60px;
        overflow: hidden;

    }
    #unread-messages {
        color: #101318;
        height: 259px;
        overflow-y: scroll;
    }
    .message-content em{
        color: #586266
    }

    .form-section{
        margin-bottom:0;
        padding-top: 5px;
    }
    .v-card__text{
        overflow: scroll;
        max-height: 350px;
    }

</style>
