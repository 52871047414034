<template>
    <v-card-text class="pa-0">
        <v-tabs class="ti-widget__tabs" fixed-tabs hide-slider height="30px" v-model="tab" v-if="payload.type==='tabs'">                        
            <div class="ti-tabs">
                <v-tab active-class="ti-tab--active" v-for="(t,i) in payload.data" :key="i" :href="'#tab-' + i">
                    {{ t.label }}
                </v-tab>
            </div>
            <v-tab-item v-for="(table, index) in payload.data" :value="'tab-'+index" :key="index" class="ti-tab__content">
                <v-data-table 
                    fixed-header 
                    disable-pagination 
                    hide-default-footer 
                    :headers="table.headers" 
                    :items="table.items"
                    :hide-default-header="hideDefaultHeader"
                    height="250px"
                    >
                    <template v-slot:item.command="{ item }" >
                        <v-btn dense small color="primary" @click="command(item)">{{ item.command.label }}</v-btn> 
                    </template>
                    <template v-slot:item.router="{ item }">
                        <a :href="item.router.action"> {{ item.router.label }} </a>
                    </template>
                    <template v-slot:item.name="{ item }">
                        {{ item.name.toUpperCase() }}
                    </template>
                    <template v-slot:item.units="{ item }">
                        <template  v-for="(unit, index) in item.units">                            
                            <v-list-item :key="index">
                                {{ unit.id }} - {{ unit.text }}
                            </v-list-item>                            
                        </template>                        
                    </template>
                    <!--
                    <template v-slot:body="{ items }">
                        <tbody>
                        
                        <tr v-for="(item, index) in items" :key="index">
                            <template v-for="(it, ind) in item">
                                <td :key="ind" v-if="it.routerto"><a :href="it.routerto">{{ it.label }}</a></td> 
                                <td :key="ind" v-else-if="it.command" class="text-right"><v-btn dense small color="primary" @click="command(item, it)">{{ it.label }}</v-btn></td> 
                                <td :key="ind" v-else class="text-center">{{ it }}</td> 
                            </template>                 
                        </tr>
                        </tbody>
                    </template>
                    -->
                </v-data-table>
            </v-tab-item>
        </v-tabs>
    </v-card-text>
</template>
<script>
export default {
    name:'Tabs',
    data() {
        return {
            tab: null
        }
    },
    computed:{
        hideDefaultHeader(){
            return this.payload.settings && this.payload.settings.hideDefaultHeader ? this.payload.settings.hideDefaultHeader : true
        }
    },
    methods:{
        hasSlot(it){
            console.log(it)
        },
        isEnabled (slot) {
            return this.enabled === slot
        },
        command(item){
            this.$root.$emit('HB::'+item.command.action, item);
        }
    },
    props:[
        'payload'
    ]
}
</script>
<style lang="scss" scoped>
.ti-widget {
    
    .ti-widget__tabs{
        
        div{
            .ti-tabs{
                a{
                    text-transform: capitalize;
                }
                display: flex;
                flex: 1 1;                
                border-bottom: 1px solid rgba(0,0,0,0.1); 
            }
            .ti-tab__content{
                max-height: 300px;
                overflow-y: auto;
            }
            .ti-tab--active {                
                border-bottom: 2px solid;
            }       
        }
 
    }
}
</style>