<template>
    <div>
        <hb-notification
            v-model="value"
            v-if="value"
            :notDismissable="!isHBUser"
            :type="notificationType"
            :title="notificationTitle"
        >
            <template v-slot:condensedActions>
                <hb-menu
                    options
                    :options-hover-type="notificationType"
                >
                    <v-list>
                        <v-list-item v-for="(item, index) in actions" :ripple="false" @click="click(item)">
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </hb-menu>
            </template>
            <template v-slot:actions>
                <hb-btn v-for="(item, index) in actions" small class="pl-2" color="secondary" @click="click(item)">{{ item.title }}</hb-btn>
            </template>
        </hb-notification>
    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex';
    import { EventBus } from '../../EventBus.js';
    export default {
        data() {
            return {
                condenseActions: false
            }
        },
        computed:{
            ...mapGetters({
                actions: 'snackbarStore/actions',
                value: 'snackbarStore/value',
                isHBUser: 'authenticationStore/isHBUser',
            }),
            notificationTitle() {
                return "You are currently viewing the Tenant Dashboard. Logout or launch the Mariposa CMS.";
            },
            notificationType() {
                return "caution";
            },
        },
        methods: {
            ...mapActions({
                snackbarSetValue: 'snackbarStore/setValue'
            }),
            click(item){
                EventBus.$emit(item.command, item.payload);
            }
        }
    }
</script>
<style scoped lang="scss">
</style>
