<template>
    <v-card-text class="pa-0 ma-0">
        <div class="ti-widget__list--header">
            <v-select dense
                v-model="what"
                :items="whats"
            ></v-select>
        </div>
        <v-list three-line class="ti-widget__list--clamped">
            <template  v-for="(val, key, i) in items">
                <div :key="i" v-if="what===key || what === 'All'">
                        <v-list-item >
                            <v-list-item-content>
                                <v-list-item-title class="text--primary">
                                    <strong>{{obo(val, key)}}</strong>
                                </v-list-item-title>                                 
                            </v-list-item-content>                            
                        </v-list-item>                        
                        <v-divider :key="i"
                        ></v-divider>                    
                </div>                              
            </template>
        </v-list>
    </v-card-text>
</template>
<script>
import moment from 'moment';
import { mapActions } from 'vuex';

export default {
    name:'Units',
    data() {
        return {
            what: 'All', 
            whatItems: ['All']
        }
    },
    mounted(){
        
    },
    methods:{
        ...mapActions({
            removeTodo:'todoStore/removeTodo'
        }),
        obo(val, key){
            console.log(val, key)
            return val.id;
        },
        properName(data){
            return data && data.Created ? data.Created.first + " " + data.Created.last : '';
        },
        contactSource(data){
            if(data && data.Created){
            let phones = data.Created.Phones.length > 0 ? data.Created.Phones[0].phone : false ;
            return phones;
            } else{
                return false;
            }
        },
        formattedContact(data){
            let name = this.properName(data);
            let contact = this.contactSource(data);
            return contact ? name + ' | ' + contact : name; 
        }
    },
    computed:{
        whats(){
            let whats = ['All'];
            if(this.$props.payload.data.headers){
                this.$props.payload.data.headers.forEach(todo=>{
                    if(!whats.includes(todo.name)){
                        whats.push(todo.name)
                    }
                });            
            }
            return whats;
        },
        items() {
            return this.payload.data.items || [];
        }
    },
    props:[
        'payload'
    ]
}
</script>
<style lang="scss" scoped>
    .ti-widget__list--header{
        display: flex;        
        border-bottom: 1px solid rgba(0,0,0,0.1);
        .v-select {
            margin: 0 15px;
        }
    }

    .ti-widget__list--clamped{
        max-height: 250px;
        overflow-y: auto; 
    }
</style>