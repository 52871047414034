<template>
    <v-card-text class="pa-0 ma-0" v-if="payload">

        <div ref="widget" class="ti-chart__wrapper" :class="{'as-row': asRow}">
            <div class="ti-chart__outer">
                <div class="ti-chart__banner">
                    <div class="ti-chart__banner--kpi font-weight-medium">{{ payload.kpi | formatChartValue }}</div>
                    <div class="ti-chart__banner--label">{{ payload.title }}</div>
                </div>
                <canvas  ref="chart"></canvas>
            </div>
            <div :v-if="payload.type==='chart' && payload.label" class="ti-chart__legend" >
                <strong class="ti-chart__legend--label">{{ payload.label }}</strong>
                <ul class="ti-chart__legend--key">
                    <li v-for="(kpi, index) in payload.data.items" :key="index">
                    <span class="ti_chart__legend--key__indicator" :style="{ backgroundColor: indicatorColor(index) }"></span>
                    <span>{{ kpi.label }}</span> <span>{{kpi.value}}</span></li>
                </ul>
            </div>
        </div>
    </v-card-text>
</template>
<script>
import numeral from 'numeral'

export default {
    name: 'Chart',
    data() {
        return {
            chart: {},
            isRow: true,
            colors:['#CE6EE3', '#4AC3AB', '#FAA838', '#67CFF5', '#F17A37', '#C6CDD4', '#38AFC6', '#E4DE3E', '#E24F60', '#4B5FC7']
        }
    },
    filters: {
        formatChartValue: function(value) {
            if(value && typeof value === 'string') {
                return numeral(value).format('$0.00a');;
            } else {
                return value;
            }

        }
    },
    mounted(){
        this.createChart();
        //this.isRow = this.$refs.widget.clientWidth < 250;
    },
    methods:{
        createChart(){


            let data = this.payload.data.items.map(dp => dp.value );
            let chart = this.$refs.chart;
            this.chart = new Chart(chart, {
                type: 'doughnut', 
                data: {
                    labels:   this.payload.data.items.map(dp => " " + dp.label ),
                    datasets: [{
                        data: data,
                        backgroundColor: this.payload.data.colors || this.colors,
                        hoverBackgroundColor: this.payload.data.colors || this.colors,
                        borderWidth: 5,
                        borderRadius: 5
                    }]
                },
                options:{
                    cutout: 82,
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            enabled: true
                        }
                    },
                }
            });
            this.$nextTick(() => {
                this.isRow = this.$refs.widget.offsetWidth > 400;
            })

        },
        indicatorColor(index){
            let colors = this.payload.data.colors && this.payload.data.colors.length ? this.payload.data.colors : this.colors;

            // console.log("colors", colors);
            // console.log("this.payload.data.items.length", this.payload.data.items.length);
            // console.log("colors.length", colors.length);
            // console.log("this.payload.data.items % colors.length", this.payload.data.items.length % colors.length);
            // console.log("colors.length % this.payload.data.items ", colors.length % this.payload.data.items );
            let remainder = this.payload.data.items.length > colors.length ? index % colors.length : index;
            // console.log("remainder", remainder);
            // console.log("colors[remainder]", colors[remainder]);
            return colors[remainder];
        }
    },
    computed:{
        asRow(){
            // return this.$refs.widget.offsetWidth;
            return this.payload.h === 2;
        },

    },
    props:[
        'payload'
    ]
}
</script>
<style lang="scss" scoped>
.ti-widget {
    // Chart
    .ti-chart__wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        border-top: 1px solid rgba(0,0,0,0.1);
        align-items: center;
        justify-content: space-around;
        &.as-row{
            flex-direction: row;
            padding: 0 20px;
        }
        .ti-widget__header{
          display: flex;
          flex: 0 1;
        }

        .ti-chart__outer {
            flex: 1 1;
            position: relative;
            width: 200px;
            max-width:200px;
            height: 200px;
            max-height:200px;
            .ti-chart__banner {
              position: absolute;
              text-align: center;
              width: 100%;
              top: 50%;
              height: 66px;
              margin-top: -33px;
              pointer-events: none;
              .ti-chart__banner--kpi {
                  font-size: 32px;
                  line-height: 40px;
              }
              .ti-chart__banner--label {
                  line-height: 1.7em;
              }
            }
        }
        .ti-chart__legend {

            width: 200px;
            height: 200px;
            max-width: 80%;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            .ti-chart__legend--label{
              margin-bottom: 15px;
              flex: 0 1;
            }
            .ti-chart__legend--key {
                list-style: none;
                margin: 0;
                padding: 0;
                display: flex;
                flex-direction: column;
                overflow: scroll;
                position: relative;

                li{
                    position: relative;
                    display: flex;
                    padding: 5px 0 0 25px;
                    justify-content:space-between;
                    flex: 1 1;
                    span{
                        /*flex: 0 1;*/
                        &:last-child{
                            /*flex: 1 0;*/
                            text-align: right;
                        }
                    }
                    span.ti_chart__legend--key__indicator{
                      width: 15px;
                      height: 15px;
                      display: block;
                      position: absolute;
                      margin-left: -23px;
                      border-radius: 3px;
                      top: 8px;
                    }
                }


            }
        }
    }
}
</style>
