<template>
    <span>
        <span :class="{ 'unit-disabled' : !showMore && index > 9}" v-for="(v, index) in f.unitdata" :key="'spaces' + index">
            <a @click.stop="viewUnit(v.id)"> {{v.number}}</a>
            <span v-show="addComma(index, f.unitdata.length)">,</span>
        </span>
        <span class="ml-1">
            <button class="ma-0 pa-0" v-show="!showMore && f.unitdata.length > 10" @click="showMore = true">and {{f.unitdata.length - 10}} more...</button>
            <button class="ma-0 pa-0" v-show="showMore && f.unitdata.length > 10" @click="showMore = false">show less</button>
        </span>
    </span>
</template>

<script type="text/babel">
    export default {
        name: 'UnitWidgetLoop',
        data: function(){
            return {
                showMore: false
            }
        },
        methods:{
            viewUnit(unit){
                this.$emit('view_unit', unit);
            },
            addComma(index, length){
                if(index < length - 1){
                    return true;
                }
                else {
                    return false;
                }
            }

        },
        props:['f', 'i']

    }
</script>
<style scoped>

    .unit-disabled{
        display:none;
    }

</style>