<template>
    <v-card>
        <v-card-subtitle>
            <span class="icon"></span>Vacancies For Available Spaces
            <button @click="deleteDashboard()" class="w-button btn-sm tertiary-btn delete-button" v-show="canDelete">
                <span class="icon"></span>
            </button>
        </v-card-subtitle>
        <v-card-text>
            <div id="vacancy-chart-holder">
                <canvas id="vacancy-chart"></canvas>
            </div>
            <div id="chart-legend">
                <router-link tag="div" class="legend-row" :to="'/units/search?statuses=%5B%22Available%22%5D'">
                    <span class="legend-row-label">
                        <span class="icon emergency" style="font-weight: 800" ></span> &nbsp; Vacant Now
                    </span>
                    <span  class="emergency"> {{vacancies[0]}} </span>
                </router-link>

                <router-link tag="div" class="legend-row" to="/reports/vacancies?period=6">
                    <span class="legend-row-label">
                        <span class="icon urgent" style="font-weight: 800" ></span> &nbsp; Vacant < 6 mo
                    </span>
                    <span  class="urgent"> {{vacancies[1]}}  </span>
                </router-link>

                <router-link tag="div" class="legend-row" to="/reports/vacancies?period=12">
                    <span class="legend-row-label">
                        <span class="icon standard" style="font-weight: 800" ></span> &nbsp; Vacant 6 - 12 mo
                    </span>
                    <span class="standard"> {{vacancies[2]}}  </span>
                </router-link>
            </div>
        </v-card-text>
    </v-card>
</template>
<script type="text/babel">
    import api from '../../assets/api.js';

    export default {
        name: 'DashboardVacancies',
        data: function(){
            return {
                chartDiv: {},
                chart:{},
                vacancies: [],
                pid: ''
            }
        },
        mounted(){
            this.chartDiv = document.getElementById("vacancy-chart");
            this.pid = this.property_id;
            this.fetchData();
        },
        computed:{

        },
        filters:{
            formatDate(date){
                return moment(date).format('MM/DD/YYYY');
            }
        },
        methods:{
            deleteDashboard(){
                api.delete(this, api.DASHBOARD, this.dashboard.id).then(r => {
                    this.$parent.$emit('HBGet:Dashboards'); //this.fetchDashboards();
                });
            },
            getDate(timeframe){
                switch(timeframe){
                    case 'six':
                        return moment().startOf('day').add(6, 'months').format('YYYY-MM-DD');
                        break;
                    case 'twelve':
                        return moment().startOf('day').add(12, 'months').format('YYYY-MM-DD');
                        break;
                    default:
                        return moment().startOf('day').format('YYYY-MM-DD');
                }
            },


            fetchData(){
                api.get(this, api.DASHBOARD_VACANCIES, {property_id: this.pid}).then(r => {
                    this.vacancies = r.vacancies;
                }).then(this.loadChart.bind(this));
            },
            loadChart(){
                var _this = this;


                var data = this.vacancies;


                if(this.chart.labels) {
                    this.chart.data.datasets[0].data = data;
                    this.chart.update();
                    return;
                }

                this.chart = new Chart(this.chartDiv, {
                    type: 'doughnut',
                    data: {
                        labels:{
                            display:false
                        },
                        datasets: [{
                            label: 'Vacancies',
                            data: data,
                            backgroundColor: [
                                'rgba(198, 40, 40, 1)',
                                'rgba(249, 168, 35, 1)',
                                'rgba(2, 136, 209, 1)'

                            ],
                            borderColor: [
                                'rgba(198, 40, 40, 1)',
                                'rgba(249, 168, 35, 1)',
                                'rgba(2, 136, 209, 1)'
                            ],
                            hoverBackgroundColor: [
                                'rgba(198, 40, 40, 1)',
                                'rgba(249, 168, 35, 1)',
                                'rgba(2, 136, 209, 1)'
                            ],
                            borderWidth: 1
                        }]
                    },
                    options:{
                        cutout: 90,
                        plugins: {
                            legend: {
                                display: false
                            },
                            tooltip:{
                                enabled: false
                            }
                        }
                    }
                });
            }
        },
        props:['property_id', 'canDelete', 'property', 'dashboard'],
        watch:{
            property_id(property_id, old_id){
                this.vacancies = [];
                this.pid = property_id;
                this.fetchData();
            }
        }

    }
</script>
<style scoped>
    h4{
        margin: 0 0 20px 0;
    }
    #vacancy-chart-holder{
        width: 200px;
        height: 200px;
        margin: 40px auto;
        position: relative;
        max-width: 100%;
    }
    #vacancy-chart{
        z-index: 100;
    }

    #chart-legend{
        width: 100%;
        max-width: 100%;
        width: 275px;
        margin: 30px auto;
        color: #586266;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        padding: 0 20px;
    }

    .dashboard-panel-row-2{
        height: 315px;

    }



    .legend-row{
        cursor: pointer;
    }


    @media (max-width: 1199px){
        .dashboard-panel-row-2{
            height: 415px;

        }
        #vacancy-chart-holder {
            width: 175px;
            height: 175px;
            margin: 40px auto 60px;;
            position: relative;
            max-width: 100%;
        }
    }

</style>
