<template>
    <v-card>
        <v-card-subtitle>
            <span class="icon"></span> Aging/Past Due
            <button @click="deleteDashboard()" class="w-button btn-sm tertiary-btn delete-button" v-show="canDelete">
                <span class="icon"></span>
            </button>
        </v-card-subtitle>
        <v-card-text>
            <div class="table">
                <div class="table-row">
                    <div class="table-head" style="flex-basis: 50%;">
                        <div>Days Past Due</div>
                    </div>
                    <div class="table-head align-right" style="flex-basis: 25%;">
                        <div>Count</div>
                    </div>
                    <div class="table-head align-right" style="flex-basis: 25%;">
                        <div>$ Amt</div>
                    </div>
                </div>
                <div style="text-align: center; padding: 50px;" v-show="isLoading($options.name)" >
                    <loader color="#00b2ce" size="20px"></loader>
                </div>


                <div class="table-row" @click="viewDetail('30')" v-show="!isLoading($options.name)">
                    <div class="table-cell" style="flex-basis: 50%;">
                        <div>1 - 30</div>
                    </div>
                    <div class="table-cell align-right" style="flex-basis: 25%;">
                        <div>{{thirty | count}}</div>
                    </div>
                    <div class="table-cell align-right" style="flex-basis: 25%;">
                        <div>{{thirty | total | formatMoney}}</div>
                    </div>
                </div>

                <div class="table-row" @click="viewDetail('90')" v-show="!isLoading($options.name)">
                    <div class="table-cell" style="flex-basis: 50%;">
                        <div>31 - 90</div>
                    </div>
                    <div class="table-cell align-right" style="flex-basis: 25%;">
                        <div>{{ninety | count}}</div>
                    </div>
                    <div class="table-cell align-right" style="flex-basis: 25%;">
                        <div>{{ninety | total | formatMoney}}</div>
                    </div>
                </div>

                <div class="table-row" @click="viewDetail('90+')" v-show="!isLoading($options.name)">
                    <div class="table-cell" style="flex-basis: 50%;">
                        <div>Over 90</div>
                    </div>
                    <div class="table-cell align-right" style="flex-basis: 25%;">
                        <div>{{overNinety | count}}</div>
                    </div>
                    <div class="table-cell align-right" style="flex-basis: 25%;">
                        <div>{{overNinety | total | formatMoney}}</div>
                    </div>
                </div>

                <div class="table-row" @click="viewDetail('90+')" v-show="!isLoading($options.name)">
                    <div class="table-cell" style="flex-basis: 50%;">
                        <div><strong>Total</strong></div>
                    </div>
                    <div class="table-cell align-right" style="flex-basis: 25%;">
                        <div><strong>{{count_all}}</strong></div>
                    </div>
                    <div class="table-cell align-right" style="flex-basis: 25%;">
                        <div><strong>{{sum_all | formatMoney}} </strong></div>
                    </div>
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>
<script type="text/babel">
    import api from '../../assets/api.js';
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import moment from 'moment';
    export default {
        name: 'AgingSummary',
        data: function(){
            return {
                thirty: [],
                ninety: [],
                overNinety: [],
                pid: ''
            }
        },
        mounted(){
            this.pid = this.property_id;
            this.fetchData();
        },
        components:{
            Loader
        },
        computed:{

            sum_all(){
                var reduced = this.thirty.reduce(function(a, b){ return a + b.balance }, 0) +
                        this.ninety.reduce(function(a, b){ return a + b.balance }, 0) +
                        this.overNinety.reduce(function(a, b){ return a + b.balance }, 0);
                return Math.round(reduced * 1e2) / 1e2
            },

            count_all(){
                return this.thirty.length + this.ninety.length + this.overNinety.length;
            }


        },
        filters:{
            formatDate(date){
                return moment(date).format('MM/DD/YYYY');
            },

            count(inv){
                return inv.length;
            },
            total(inv){
                var reduced = inv.reduce(function(a, b){ return a + b.balance }, 0);
                return Math.round(reduced * 1e2) / 1e2
            },
        },
        methods:{
            deleteDashboard(){
                api.delete(this, api.DASHBOARD, this.dashboard.id).then(r => {
                    this.$parent.$emit('HBGet:Dashboards'); //this.fetchDashboards();
                });
            },
            fetchData(){
                this.startLoading(this.$options.name);

                api.get(this, api.DASHBOARD_AGING, { property_id: this.pid}).then(r => {
                    this.thirty = r.thirty;
                    this.ninety = r.ninety;
                    this.overNinety = r.overNinety;

                });
            },
            viewDetail(timeperiod){
                switch (timeperiod){
                    case '30':
                        this.$router.push('/billing/aging-detail?end_date=' + moment().subtract(1, 'days').format('YYYY-MM-DDTHH:mm:ssz') + '&start_date=' + moment().subtract(30, 'days').format( 'YYYY-MM-DDTHH:mm:ssz'));
                        break;
                    case '90':
                        this.$router.push('/billing/aging-detail?start_date=' + moment().subtract(90, 'days').format('YYYY-MM-DDTHH:mm:ssz') + '&end_date=' + moment().subtract(31, 'days').format( 'YYYY-MM-DDTHH:mm:ssz'));
                        break;
                    case '90+':

                        this.$router.push('/billing/aging-detail?end_date=' + moment().subtract(91, 'days').format('YYYY-MM-DDTHH:mm:ssz'));
                        break;
                }
            }
        },
        watch:{
            property_id:function (property_id, old_id){
                this.thirty = [];
                this.ninety = [],
                this.overNinety = []
                this.pid = property_id;
                this.fetchData();
            }
        },
        props:['property_id', 'canDelete', 'property', 'dashboard']

    }
</script>
<style scoped>
    h4{
        margin: 0 0 20px 0;
    }

    .row.total{
        margin-top: 25px;
        border-top: 1px solid #e2e2e2;


    }
    .dashboard-panel-row-2{
        height: 315px;
    }


</style>
