<template>
    <v-card class="ti-widget hb-elevation-medium hb-border-default" :style="computedStyle()">
        <div class="ti-widget__wrapper" v-show="payload">
            <div class="v-card__header ti-widget__header" >
                <div class="v-card__header--text">
                    <v-card-title class="ti-widget__title font-weight-medium" v-if="payload.title">{{ payload.title }}</v-card-title>
                    <v-card-title class="ti-widget__subtitle" v-if="payload.subtitle">{{ payload.subtitle }}</v-card-title>
                </div>
                <v-spacer></v-spacer>
                <v-card-actions class="py-0" v-if="canDelete">
                    <hb-btn icon active-state-off tooltip="Rotate" @click="rotateItem(payload)">
                        mdi-refresh
                    </hb-btn>
                    <hb-btn icon active-state-off tooltip="Remove from Dashboard" @click="deleteItem(payload)">
                        mdi-delete
                    </hb-btn>
                </v-card-actions>
            </div>
            <div class="v-card__content ti-widget__content" v-if="payload.data">
                <component :is="payload.type" :payload.sync="payload" />
            </div>
            <div v-else class="ti-widget__error text-center">
                {{ payload.error || "Please select a property" }}
            </div>
        </div>
        <v-progress-circular v-show="!payload" indeterminate size="64" class="ti-widget__progress"></v-progress-circular>
    </v-card>
</template>
<script>
import Bar from './components/Bar.vue';
import Linechart from './components/Line.vue';
import Chart from './components/Chart.vue';
import DataTable from './components/DataTable.vue';
import Tabs from './components/Tabs.vue';
import Leads from './components/Leads.vue';
import Tasks from './components/Tasks.vue';
import Units from './components/Units.vue';

import Vue from 'vue';
import { mapActions } from 'vuex';

Vue.component('linechart', Linechart);
Vue.component('bar', Bar);
Vue.component('chart', Chart);
Vue.component('tabs', Tabs);
Vue.component('datatable', DataTable);
Vue.component('leads', Leads);
Vue.component('tasks', Tasks);
Vue.component('units', Units);

export default {
    name:'Widget',
    data() {
        return {
        }
    },
    created(){
        
    },
    props:[
        'payload',
        'canDelete',
        'width',
        'height'
    ],
    methods:{
        ...mapActions({
            deleteWidget:'dashboardsStore/deleteWidget'
        }),
        rotateItem(payload){
            if(payload.w === 3){
                payload.w = 2
                payload.h = 3
            } else {
                payload.w = 3
                payload.h = 2
            }
            this.$emit('resize');
            // var saved = {
            //     dashboard_type_id: drag.item.getAttribute('data-name'),
            //     sort:0
            // };
            //
            // // TODO: fix this hack
            // this.tilt = true;
            //
            // this.addWidget({context:this, dashboards:[saved]}).then(r =>{
            //     this.tilt = false;
            //     if(r){
            //         this.fetchDashboards()
            //     }
            // });




        },
        deleteItem(payload){
            this.deleteWidget({
                context: this,
                widget:payload
            });
        },
        computedStyle(){
            let width = this.$props.width ? 'width:'+this.$props.width+';' : '';
            let height = this.$props.height ? 'height:'+this.$props.height+';' : '';
            return width + height;
        }
    },
    components:{
        Chart,
        Tabs,
        DataTable,
        Leads,
        Tasks
    }
}
</script>
<style lang="scss" scoped>
.ti-widget {
    width:100%;
    height: 100%;
    overflow: hidden;
    .ti-widget__wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    .ti-widget__error{

        border-top: 1px solid rgba(0,0,0,0.1);
    }
    .ti-widget__progress{
        margin: 0 auto;
    }
    .v-card__text {
      flex: 1;
      height: 100%;
    }
    .ti-widget__header {
        padding: 15px 5px;
        display: flex;
        flex: 0 1;

        .ti-widget__title {
            font-size: 1.2em;
            line-height: 1em;
            padding-top: 0px;
            padding-bottom: 5px;
        }
        .ti-widget__subtitle {
            opacity: 0.8;
            font-size: 0.9em;
            line-height: 1em;
            font-weight: normal;
            padding-top: 0px;
            padding-bottom: 0px;
        }
    }
    .v-card__content.ti-widget__content {
      flex: 1;
    }
}
</style>
