
export const tableKeys = {
	data() {
		return {
			cols: [
				{
					label: 'Address',
					key: "address",
					sortable: true
				},
				{
					label: 'Number of Leases',
					key: "num_leases",
					sortable: true
				},

				{
					label: "Avg Months",
					key: "avg_months",
					sortable: true
				},

				{
					label: "Standard Deviation",
					key: "std_deviation",
					sortable: true
				},
			],
			filters:{
				search:{
					property_id: [],
				},
				columns:[
					'address',
					'num_leases',
					'avg_months',
					'std_deviation'
				],
				sort:{
					field: 'address',
					dir: 'ASC'
				},
				page: 1,
				limit:25,
				offset:0
			},
		}
	}
};