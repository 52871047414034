<template>
    <v-card-text class="pa-0 ma-0">
        <div class="ti-widget__list--header">
            <v-select dense
                v-model="what"
                :items="whats"
            ></v-select>
            <v-select dense
                v-model="when"
                :items="whens"
            ></v-select>
        </div>
        <v-list three-line class="ti-widget__list--clamped">
            <template  v-for="(val, key, i) in tasks">
                <div :key="i" v-if="when===key || when === 'All'">
                    <template v-for="(todo, ind) in val">
                        <v-list-item :key="ind"  v-if="(what===todo.type || what === 'All') && todo.type !== 'group'">
                            <v-list-item-content>
                                <v-list-item-title class="text--primary">
                                    <strong>{{ todo && todo.type ? todo.type : 'None' }}</strong>
                                </v-list-item-title> 
                                <v-list-item-subtitle class="text--primary" >
                                    {{ formattedContact(todo.todo) }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-if="todo.todo && todo.todo.details">
                                    {{ todo.todo.details }}
                                </v-list-item-subtitle>                         
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn small color="primary" depressed elevation="0" dense @click="complete(todo.todo)">Complete</v-btn>
                                {{ endDate(todo.todo) }}
                            </v-list-item-action>
                        </v-list-item>
                        <template v-else-if="todo.type" >
                            <v-list-group :key="ind">
                                <template v-slot:activator>
                                    <v-list-item-title> <strong>{{ todo && todo.event_type ? todo.event_type : 'None' }}</strong></v-list-item-title>
                                </template>   
                                
                                <template v-for="(todoo, inde) in todo.Children"  >  
                                    <v-list-item background="yellow" :key="inde">
                                        <v-list-item-content>
                                            <v-list-item-title class="text--primary">
                                                <strong>{{ todoo && todoo.type ? todoo.type : 'None' }}</strong>
                                            </v-list-item-title> 
                                            <v-list-item-subtitle class="text--primary" >
                                                {{ formattedContact(todoo) }}
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle v-if="todoo && todoo.details">
                                                {{ todoo.details }}
                                            </v-list-item-subtitle>                         
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-btn small color="primary" depressed elevation="0" dense @click="complete(todoo)">Complete</v-btn>
                                            {{ endDate(todoo) }}
                                        </v-list-item-action>
                                    </v-list-item>
                                </template>
                            </v-list-group>
                        </template>
                        <v-divider :key="i+'_'+ind"
                        ></v-divider>
                    </template>  
                </div>              
                
            </template>
        </v-list>
    </v-card-text>
</template>
<script>
import moment from 'moment';
import { mapActions } from 'vuex';

export default {
    name:'Tasks',
    data() {
        return {
            when:'All',
            what: 'All',
            whatItems: ['All'],
            whenItems: ['All']
        }
    },
    mounted(){
        
    },
    methods:{
        ...mapActions({
            removeTodo:'todoStore/removeTodo'
        }),
        properName(data){
            return data && data.Created ? data.Created.first + " " + data.Created.last : '';
        },
        contactSource(data){
            if(data && data.Created){
            let phones = data.Created.Phones.length > 0 ? data.Created.Phones[0].phone : false ;
            return phones;
            } else{
                return false;
            }
        },
        formattedContact(data){
            let name = this.properName(data);
            let contact = this.contactSource(data);
            return contact ? name + ' | ' + contact : name; 
        },
        endDate(todo){
             return todo && todo.Event ? moment(todo.Event.end_date,'YYYY-MM-DD').format('MM/DD/YYYY') : ''
        },
        complete(arg){
            this.removeTodo({context: this, todo: arg})
        }
    },
    computed:{
        whats(){
            let whats = ['All']
            for(let block in this.$props.payload.data) {
                this.$props.payload.data[block].forEach(todo=>{
                    if(!whats.includes(todo.type)){
                        whats.push(todo.type)
                    }
                })
            }
            return whats;
        },
        whens(){
            let when = ['All']
            for(let block in this.$props.payload.data) {
                when.push(block);
            }
            return when;
        },
        tasks() {
            return this.payload.data;
        }
    },
    props:[
        'payload'
    ]
}
</script>
<style lang="scss" scoped>
    .ti-widget__list--header{
        display: flex;        
        border-bottom: 1px solid rgba(0,0,0,0.1);
        .v-select {
            margin: 0 15px;
        }
    }

    .ti-widget__list--clamped{
        max-height: 250px;
        overflow-y: auto; 
    }
</style>