<template>
    <v-card>
        <v-card-subtitle>
            <i class="fa fa-dollar"></i> Yearly Revenue
            <button @click="deleteDashboard()" class="w-button btn-sm tertiary-btn delete-button" v-show="canDelete">
                <span class="icon"></span>
            </button>
        </v-card-subtitle>
        <v-card-text>
            <div class="revenue-desc" v-show="revenue.length">
                <h5><i class="fa fa-calendar"></i> Dates</h5>
                <h2>{{dateRange}}</h2>
            </div>
            <div id="revenue-chart-holder">
                <canvas id="monthlyRevenueChart" height="225"></canvas>
            </div>
        </v-card-text>
    </v-card>
</template>
<script type="text/babel">
    import api from '../../assets/api.js';
    import moment from 'moment';
    export default {
        name: 'MonthlyRevenue',
        data: function(){
            return {
                chartDiv: {},
                chart:{},
                revenue: [],
                pid: ''
            }
        },
        mounted(){
            this.chartDiv = document.getElementById("monthlyRevenueChart");
            this.pid = this.property_id;
            this.fetchData();
        },
        computed:{
            dateRange(){
                if(!this.revenue[0]) return null;
                return this.revenue[0].month + "/" + this.revenue[0].year + " - " + this.revenue[11].month + "/" + this.revenue[11].year
            }

        },
        filters:{

        },
        methods:{
            deleteDashboard(){
                api.delete(this, api.DASHBOARD, this.dashboard.id).then(r => {
                    this.$parent.$emit('HBGet:Dashboards'); //this.fetchDashboards();
                });
            },
            fetchData(){
                api.get(this, api.DASHBOARD_MONTHLY_REVENUE_SUMMARY, {property_id: this.pid}).then(r => {
                    this.revenue = r.revenue;
                }).then(this.loadChart.bind(this));
            },
            loadChart(){
                var _this = this;

                var labels = this.revenue.map(function(r) {
                    return moment(r.month, "M").format("MMM");
                });

                var data = this.revenue.map(function(r) {
                    return r.amount;
                });

                if(this.chart.config) {
                    this.chart.destroy();

                }
                this.chart = new Chart(this.chartDiv, {
                    type: 'bar',

                    data:{
                        labels: labels,
                        datasets: [{
                            data: data,
                            backgroundColor: 'rgba(2, 136, 209, 1)',
                            borderColor: 'rgba(2, 136, 209, 1)',
                            hoverBackgroundColor: 'rgba(0, 212, 255, 1)'
                        }]
                    },
                    options:{
                        plugins: {
                            legend: {
                                display: false
                            },
                            tooltip:{
                                backgroundColor: 'rgba(245,247,248,1)',
                                bodyFontColor: '#586266',
                                titleFontColor: '#586266',
                                displayColors:false,

                                callbacks:{
                                    label: function(tooltipItem, data){
                                        return "$" + tooltipItem.yLabel.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
                                        }
                                    }
                            },
                        },
                        scales:{
                            y: {
                                display: true,
                                beginAtZero: true,
                                ticks: {
                                    steps: 10,
                                    stepValue: 5,
                                    max: Math.max.apply(null, data) * 1.10
                                }
                            }
                        }
                    }

                });
            }
        },
        watch:{
            property_id:function (property_id, old_id){
                this.revenue = [];
                this.pid = property_id;
                this.fetchData();
            }
        },
        props:['property_id', 'canDelete', 'property', 'dashboard']

    }
</script>
<style scoped>
    h4{
        margin: 0 0 20px 0;
    }
    #revenue-chart-holder{
        width: 300px;
        max-width: 100%;
        margin: 20px auto;
    }
    .revenue-desc{
        margin: 40px auto 50px;

    }
    .revenue-desc h5{
        color: #abb1b9;
        margin: 0;
        padding: 0;
        font-size: 18px;
        text-align: center;

    }
    .revenue-desc h2 {
        font-size: 25px;
        line-height: 40px;
        color: #586266;
        font-weight: 800;
        text-align: center;
        border: none;
        padding: 0;
        margin: 0;
    }

    .dashboard-panel-row-1{
        height: 415px;
    }

</style>
