<template>
    <v-card class="ti-widget">
        <div class="ti-widget__wrapper" v-show="payload">
            <div class="v-card__header ti-widget__header" :class="{'has-tabs':payload.type==='tabs'}">
                <v-card-title class="ti-widget__title" v-if="payload.title">{{ payload.title }}</v-card-title>
                <v-card-title class="ti-widget__subtitle" v-if="payload.subtitle">{{ payload.subtitle }}</v-card-title>                
            </div>
            <div class="v-card__content ti-widget__content">
                <v-card-text :class="{'pa-0':(payload.type==='tabs')}">
                    <div class="ti-chart__wrapper" v-if="payload.type==='chart'">
                        <div class="ti-chart__outer">
                            <div class="ti-chart__banner">
                                <div class="ti-chart__banner--kpi">{{ payload.kpi }}</div>
                                <div class="ti-chart__banner--label">{{ payload.title }}</div>                        
                            </div>
                            <canvas ref="chart"></canvas>
                        </div>
                        <div :v-if="payload.type==='chart' && payload.label" class="ti-chart__legend" >
                            <span class="font-weight-medium">{{ payload.label }}</span>
                            <ul class="ti-chart__legend--key">
                                <li v-for="(kpi, index) in payload.data" :key="index"><span>{{ kpi.label }}</span> <span>{{kpi.value}}</span></li>
                            </ul>
                        </div>
                    </div>
                    
                    <v-data-table v-if="payload.type==='table'" :headers="payload.data.headers" :items="payload.data.items"></v-data-table>
                    
                    <v-tabs class="ti-widget__tabs" fixed-tabs hide-slider height="30px" v-model="tab" v-if="payload.type==='tabs'">                        
                        <div class="ti-tabs">
                            <v-tab active-class="ti-tab--active" v-for="(t,i) in payload.data.tabs" :key="i" :href="'#tab-' + i">
                                {{ t.label }}
                            </v-tab>
                        </div>
                        <v-tab-item v-for="(table, index) in payload.data.tabs" :value="'tab-'+index" :key="index" class="ti-tab__content">
                            <v-data-table disable-pagination hide-default-footer :headers="table.headers" :items="table.items"></v-data-table>
                        </v-tab-item>
                    </v-tabs>
                </v-card-text>
            </div>
        </div>
        <v-progress-circular v-show="!payload" indeterminate size="64" class="ti-widget__progress"></v-progress-circular>
    </v-card>
</template>
<script>
export default {
    name:'Advanced',
    data() {
        return {
            tab: null,
            chart: {},
            colors:['#CE6EE3', '#4AC3AB', '#FAA838', '#67CFF5', '#F17A37', '#C6CDD4', '#38AFC6', '#E4DE3E', '#E24F60', '#4B5FC7']
        }
    },
    mounted(){        
        switch (this.payload.type) {
            case 'chart': return this.createChart();
            default: return false;
        }  
    },
    computed:{
        load(){ return this.payload ? true : false; },
        kpi(){ return '90%'; }
    },
    watch:{
        payload:function(n,o){
            
        }
    },
    methods:{
        createChart(){
            let data = this.payload.data.map(dp => dp.value );
            let chart = this.$refs.chart;
            this.chart = new Chart(chart, {
                    type: 'doughnut',
                    data: {
                        labels: {
                            display:false
                        },
                        datasets: [{
                            data: data,
                            backgroundColor: this.colors,                            
                            hoverBackgroundColor: this.colors,
                            borderWidth: 5
                        }]
                    },
                    options:{
                        cutout: 90,
                        plugins: {
                            legend: {
                                display: false
                            },
                            tooltip:{
                                enabled: false
                            }
                        }
                    }
                });
        }
    },
    props:[
        'payload'
    ]
}
</script>
<style lang="scss" scoped>
.ti-widget {
    
    .ti-widget__progress{
        margin: 0 auto;
    }
    .ti-widget__header {
        padding: 35px 5px 15px;
        border-bottom: 1px solid rgba(0,0,0,0.1);
        &.has-tabs {
            border-bottom: none;
        }
        .ti-widget__title {
            font-size: 1.2em;
            line-height: 1em;
            font-weight: bold;
            padding-top: 0px;
            padding-bottom: 5px;
        }
        .ti-widget__subtitle {
            opacity: 0.8;
            font-size: 0.9em;            
            line-height: 1em;
            font-weight: normal;
            padding-top: 0px;
            padding-bottom: 0px;
        }
    }
    .ti-widget__tabs{
        
        div{
            .ti-tabs{
                a{
                    text-transform: capitalize;
                }
                display: flex;
                flex: 1 1;                
                border-bottom: 1px solid rgba(0,0,0,0.1); 
            }
            .ti-tab__content{
                max-height: 300px;
                overflow-y: auto;
            }
            .ti-tab--active {                
                border-bottom: 2px solid;
            }       
        }
 
    }

    // Chart
    .ti-chart__wrapper {        
        display: flex;
        flex-direction: column;
        .ti-chart__outer {
            flex: 1 1;
            position: relative;
            width: 200px;
            margin: 0 auto;
            .ti-chart__banner {
                position: absolute;
                text-align: center;
                width: 100%;
                top: 40%;

                .ti-chart__banner--kpi {
                    font-size: 35px;
                    font-weight: bold;
                }
                .ti-chart__banner--label {
                    line-height: 1.7em;
                }
            }
        }
        .ti-chart__legend {            
            padding-left: 20px;
            width: 180px;
            .ti-chart__legend--key {
                list-style: none;
                margin: 0;
                padding:0 0 0 20px;
                display: flex;
                flex-direction: column;
                li{
                    display: flex;
                    flex: 1 1;
                    span{
                        flex: 1 1;
                        &:last-child{
                            flex-grow: 0;
                            text-align: right;
                        }
                    }
                }
                li::before {
                    content: "\25A0"; 
                    font-weight: bold;
                    font-size: 1.5em;
                    display: inline-block;
                    width: 1em; 
                    margin-left: -1em;
                }
                li:nth-child(1){
                    &::before {
                        color: #CE6EE3;                        
                    }
                }
                li:nth-child(2){
                    &::before {
                        color: #4AC3AB; 
                    }
                }
                li:nth-child(3){
                    &::before {
                        color: #FAA838; 
                    }
                }
                li:nth-child(4){
                    &::before {
                        color: #67CFF5; 
                    }
                }
                li:nth-child(5){
                    &::before {
                        color: #F17A37; 
                    }
                }
                li:nth-child(6){
                    &::before {
                        color: #C6CDD4; 
                    }
                }
                li:nth-child(7){
                    &::before {
                        color: #38AFC6; 
                    }
                }
                li:nth-child(8){
                    &::before {
                        color: #E4DE3E; 
                    }
                }
                li:nth-child(9){
                    &::before {
                        color: #E24F60; 
                    }
                }
                li:nth-child(10){
                    &::before {
                        color: #4B5FC7; 
                    }
                }
            }
        }
    }
}
</style>