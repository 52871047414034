<template>
    <v-card-text class="pa-0 ma-0">
        <div class="ti-widget__list--header">
            <v-select dense
                :items="whatItems"
            ></v-select>
            <v-select dense
                :items="whenItems"
            ></v-select>
        </div>
        <v-list three-line class="ti-widget__list--clamped">
            <template  v-for="(lead, index) in payload.data">
                <v-list-item :key="lead.id">
                    <v-list-item-content>
                        <v-list-item-title>
                           <strong>{{ title }}</strong>
                        </v-list-item-title>
                        <v-list-item-subtitle class="text--primary" >
                            {{ lead.source || lead.status || '' }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="text--primary" >
                            {{ name(lead) }} {{ phone(lead) }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle >
                            {{ lead.content || lead.note || '' }}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-btn>Complete</v-btn>
                    </v-list-item-action>
                </v-list-item>
                <v-divider
                    v-if="index + 1 < payload.data.length"
                    :key="index"
                ></v-divider>
            </template>
        </v-list>
    </v-card-text>
</template>
<script>
export default {
    name:'Leads',
    data() {
        return {
            whatItems: ['All Tasks'],
            whenItems: ['Today']
        }
    },
    mounted(){
    },
    methods:{
        name(lead) {
            return lead.Contact.first + " " + lead.Contact.last;
        },
        phone(lead) {
            lead.Contact.Phones.length > 0 ? ' | ' + lead.Contact.Phones[0].phone : '';
        }
    },
    computed:{
        title(){ return 'First Follow Up Call'; }
    },
    props:[
        'payload'
    ]
}
</script>
<style lang="scss" scoped>
    .ti-widget__list--header{
        display: flex;        
        border-bottom: 1px solid rgba(0,0,0,0.1);
        .v-select {
            margin: 0 15px;
        }
    }

    .ti-widget__list--clamped{
        max-height: 350px;
        overflow-y: auto; 
    }
</style>