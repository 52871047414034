<template>
    <v-card>
        <v-card-subtitle>
            <i class="fa fa-envelope"></i> Available Spaces
            <button @click="deleteDashboard()" class="w-button btn-sm tertiary-btn delete-button" v-show="canDelete">
                <span class="icon"></span>
            </button>
        </v-card-subtitle>
        <v-card-text v-if="!noProperty" class="mb-4">
            <v-row class="mb-3" id="unread-count" justify="center">
                <span style="cursor:pointer">
                    <h2>{{ count }}</h2>
                    <h6>Available Space<span v-show="count != 1">s</span></h6>
                    <h6 v-if="properties && properties[0].name" class="overline mt-1">{{properties[0].name}}</h6>
                </span>
            </v-row>
            <v-container class="ma-0 pa-0">
                <v-row v-for="(f, i) in finalCategories" class="ma-0 pa-0 horizontal-form-row" :class="{ 'lined' : i < finalCategories.length - 1}" :key="'category' + i">
                    <v-col cols="12" sm="4">
                        <v-card
                        class="ma-0 pa-0"
                        flat
                        tile
                        >
                            <span class="subtitle-2 mr-3">{{f.name}}</span>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="8">
                        <v-card
                        class="ma-0 pa-0"
                        flat
                        tile
                        >
                            <unit-widget-loop :f="f" :i="i" @view_unit="viewUnit"></unit-widget-loop>
                        </v-card>
                    </v-col>
                </v-row>

            </v-container>
        </v-card-text>
        <v-card-text v-if="noProperty" class="my-12">
            <v-row id="no-properties">
                <h6>Please select a single property from 'My Properties' searchbar to view available units</h6>
            </v-row>
        </v-card-text>

        <v-navigation-drawer
            temporary
            app
            right
            width="877"
            v-model="unitDrawer"
        >
            <unit-view
                v-if="unit_id"
                :unit_id="unit_id"
                :unitDrawer="unitDrawer"
                @close="unitDrawer = false"
                :fromUnitWidget="fromUnitWidget"
            >
            </unit-view>
        </v-navigation-drawer>
    </v-card>
</template>
<script type="text/babel">
    import api from '../../assets/api.js';
    import Status from '../includes/Messages.vue';
    import UnitWidgetLoop from './UnitWidgetLoop.vue';
    import UnitView from '../units/UnitView.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import { EventBus } from '../../EventBus.js';
    import { mapGetters } from 'vuex';
    import moment from 'moment';
    export default {
        name: 'Units',
        data: function(){
            return {
                units: [],
                categories: [],
                unitDrawer: false,
                categoryIDs: [],
                finalCategories: [],
                pid: '',
                unit_id: '',
                unit_number: '',
                fromUnitWidget: false,
                close_confirm: false,
                showMore: false,
                showWindow: false,
                noProperty: false,
            }
        },
        mounted(){
            EventBus.$on('unit_available_changed', this.fetchData);
            EventBus.$on('unit_edited', this.fetchData);
        },
        async created(){
            if(this.property_id){
                this.pid = this.property_id;
                await this.fetchData();
            } else if(this.properties && this.properties.length >= 1){
                this.pid = this.properties[0].id;
                await this.fetchData();
            } else {
                this.noProperty = true;
            }
        },
        destroyed(){
            EventBus.$off('unit_available_changed', this.fetchData);
            EventBus.$off('unit_edited', this.fetchData);
        },
        components:{
            Loader,
            Status,
            UnitWidgetLoop,
            UnitView
        },
        computed:{
            ...mapGetters({
                properties: 'propertiesStore/properties'
            }),
            count(){
                if(!this.units) return 0;
                    return this.units.length;
            },
            displayData(){
                var availableUnits = this.units;
                var unitCategories = this.categories;
                var prelimArray = [];
                var finalArray = [];

                unitCategories.forEach(function(e){
                    prelimArray.push({
                        id: e.id,
                        name: e.name,
                        unitdata: [],
                    });
                });
                prelimArray.push({
                    id: null,
                    name: 'Uncategorized',
                    unitdata: [],
                });

                prelimArray.forEach(function(item) {
                    var existing = availableUnits.filter(function(v, i) {
                        return v.category_id === item.id;
                    });
                    if(existing.length){
                        existing.forEach(function(i) {
                            item.unitdata.push(i);
                        }
                    )};
                    existing = '';

                });

                prelimArray.forEach(function(item) {
                    if(item.unitdata.length > 0){
                        finalArray.push(item);
                    }
                });

                this.finalCategories = finalArray;

            }
        },
        methods:{
            viewUnit(unit){
                this.unitDrawer = true;
                this.fromUnitWidget = true;
                this.unit_id = unit;
            },
            deleteDashboard(){
                api.delete(this, api.DASHBOARD, this.$props.property.id).then(r => {
                    this.$parent.$emit('HBGet:Dashboards');
                });
            },
            async fetchData(){
                this.units = [];
                this.categories = [];
                this.categoryIDs = [];
                this.startLoading(this.$options.name);
                let r = await api.get(this, api.PROPERTIES + this.pid + '/units/available?limit=-1').then(r => {
                    this.units = r.units;
                });
                let s = await api.get(this, api.CATEGORIES).then(r => {
                    this.categories = r.categories;
                    this.categoryIDs = r.categories.map(a => a.id);
                });
                this.displayData;
            }

        },
        watch:{
            property_id: function (){
                this.units = [];
                this.pid = this.property_id;
                this.fetchData();
            },
            properties: function (){
                this.units = [];
                if(this.properties && this.properties[0].id){
                    this.pid = this.properties[0].id;
                }
                this.fetchData();
            }
        },
        props:['property_id', 'canDelete', 'property']

    }
</script>
<style scoped>

    #unread-count {
        width: 100%;
        margin: 0px auto;
        position: relative;

    }
    #unread-count  h2 {
        font-size: 52px;
        line-height: 52px;
        color: #00b2ce;
        font-weight: 800;
        text-align: center;
        border: none;
        padding: 0;
        margin: 0;
    }

    #unread-count h6{
        font-size: 14px;
        line-height: 18px;
        text-transform: uppercase;
        margin: 0;
        font-weight: 800;
        color: #586266;
        text-align: center;
        padding: 0 20px;
        border: none;
    }

    #no-properties {
        width: 340px;
        height: 75px;
        margin: 50px auto 20px;
        position: relative;
    }

    #no-properties  h6{
        font-size: 14px;
        line-height: 18px;
        text-transform: uppercase;
        line-height: 21px;
        margin: 0;
        font-weight: 800;
        color: #00b2ce;
        text-align: center;
        padding: 0 20px;
        border: none;
    }

    .v-card__text{
        overflow: scroll;
        max-height: 350px;
    }

</style>
