<template>
    <v-card class="ti-widget">
        <div class="v-card__header ti-widget__header">
            <v-card-title class="ti-widget__title font-weight-medium" v-if="title">{{ title }}</v-card-title>
            <v-card-title class="ti-widget__subtitle" v-if="subtitle">{{ subtitle }}</v-card-title>
        </div>
        <div class="v-card__content ti-widget__content">
            <v-card-text>
            {{ content }}
            </v-card-text>
        </div>
    </v-card>
</template>
<script>
export default {
    name:'Basic',
    data() {
        return {
            content: 'test'
        }
    },
    props:[
        'title',
        'subtitle'
    ]
}
</script>
<style lang="scss" scoped>
.ti-widget {
    .ti-widget__header {
        padding: 35px 5px 15px;
        border-bottom: 1px solid rgba(0,0,0,0.1);
        .ti-widget__title {
            font-size: 1.2em;
            line-height: 1em;
            padding-top: 0px;
            padding-bottom: 5px;
        }
        .ti-widget__subtitle {
            opacity: 0.8;
            font-size: 0.9em;            
            line-height: 1em;
            font-weight: normal;
            padding-top: 0px;
            padding-bottom: 0px;
        }
    }
}
</style>