<template>
    <v-card>

        <v-card-subtitle>
            <i class="fa fa-envelope"></i> Overlocked Spaces
            <button @click="deleteDashboard()" class="w-button btn-sm tertiary-btn delete-button" v-show="canDelete">
                <span class="icon"></span>
            </button>
        </v-card-subtitle>

        <v-card-text v-if="property && property.id" fill-height>

            <v-row id="unread-count" justify="center">
                <span style="cursor:pointer">
                    <h2>{{ count }}</h2>
                    <h6 v-if="view === 'all'">Overlocked Space<span v-show="count != 1">s</span></h6>
                    <h6 v-if="view === 'overlock'">Space<span v-show="count != 1">s</span> To Overlock</h6>
                    <h6 v-if="view === 'unlock'">Space<span v-show="count != 1">s</span> To Unlock</h6>
                    <h6 v-if="property" class="overline mt-1">{{property.name}}</h6>
                </span>
            </v-row>
          <v-row id="unread-count" justify="center">
            <v-col class="text-center">
              <v-btn-toggle v-model="view" mandatory>
                <v-btn  value="all" @click="fetchOverlocked">
                  All Overlocked
                </v-btn>
                <v-btn  value="overlock"  @click="fetchToOverlock">
                  To Overlock
                </v-btn>
                <v-btn value="unlock" @click="fetchToUnlock">
                  To Unlock
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>

            <v-container fill-height class="ma-0 pa-0">
                <v-row align="center" class="horizontal-form-row lined clickable ma-0 pa-0" v-for="(u, i) in units" :key="'space' + i">
                    <v-col cols="6" class="ma-0 pa-0">
                        <div>
                            <!-- <strong>Space #{{u.number}} <span class="status ml-2 mt-1" :class="'status_' + u.state | slugify">{{u.state}}</span></strong><br />-->
                            <strong>Space #{{u.number}} <span class="status ml-2 mt-1" :class="'status_' + u.Lease.Standing.name  | slugify">{{u.Lease.Standing.name}}</span></strong><br />
                        </div>
                    </v-col>
                    <v-spacer></v-spacer>

                    <v-col>
                      <v-btn primary v-if="view === 'overlock'"  @click.stop="overlockUnit(u)">
                        <v-icon size="20">mdi-lock</v-icon> Lock Unit
                      </v-btn>
                      <v-btn primary v-else @click.stop="unlockUnit(u)">

                        <v-icon size="20" class="mr-2">mdi-lock-open-variant</v-icon>Unlock Unit
                      </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-text v-if="!property.id">
            <v-row id="no-properties">
                <h6 class="mt-12">Please select a single property to view overlocked spaces</h6>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script type="text/babel">
    import api from '../../assets/api.js';
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import { EventBus } from '../../EventBus.js';
    import moment from 'moment';
    export default {
        name: 'Overlock',
        data: function(){
            return {
                view: 'all',
                units: [],
                pid: '',
                unit_id: '',
                unit_number: '',
                close_confirm: false,
                showWindow: false
            }
        },
        mounted(){
            if(this.property.id){
              this.fetchOverlocked();
            }
        },
        created(){
            EventBus.$on('unit_overlocked', this.fetchData);
        },
        destroyed(){
            EventBus.$off('unit_overlocked', this.fetchData);
        },
        components:{
            Loader,
            Status
        },
        computed:{
            count(){
                if(!this.units) return 0;
                return this.units.length;
            }
        },
        methods:{
            deleteDashboard(){
                api.delete(this, api.DASHBOARD, this.dashboard.id).then(r => {
                    this.$parent.$emit('HBGet:Dashboards'); //this.fetchDashboards();
                });
            },
            confirmUnlock(u){
                this.unit_id = u.id;
                this.unit_number = u.number;
                this.close_confirm = true;
            },
            fetchData(){
               switch(this.view){
                   case 'all':
                       this.fetchOverlocked();
                       break;
                   case 'overlock':
                       this.fetchToOverlock();
                       break;
                    case 'unlock':
                       this.fetchToUnlock();
                       break;
               }
            },
            overlockUnit(unit){
                api.post(this, api.UNITS + unit.id + '/overlock').then(r => {
                    this.fetchData();
                });
            },
            unlockUnit(unit){
                api.delete(this,  api.UNITS + unit.id + '/overlock').then(function(){
                    this.fetchData();
                });
            },
            fetchOverlocked(){
                this.units = [];
                this.startLoading(this.$options.name);
                api.get(this, api.PROPERTIES + this.property.id + '/units/overlock').then(r => {
                    this.units = r.units;
                });
            },
            fetchToUnlock(){
                this.units = [];
                this.startLoading(this.$options.name);
                api.get(this, api.PROPERTIES + this.property.id + '/units/to-unlock').then(r => {
                    this.units = r.units;
                });
            },
            fetchToOverlock(){
                this.units = [];
                this.startLoading(this.$options.name);
                api.get(this, api.PROPERTIES + this.property.id + '/units/to-overlock').then(r => {
                    this.units = r.units;
                });
            }

        },
        watch:{
            "property.id": function (){
                this.units = [];
                
                if(this.property.id){
                  this.fetchData();
                }

            },
        },
        props:['property_id', 'canDelete', 'property', 'dashboard']

    }
</script>
<style scoped>

    #unread-count {
        width: 100%;
        margin: 0px auto;
        position: relative;

    }
    #unread-count  h2 {
        font-size: 52px;
        line-height: 52px;
        color: #00b2ce;
        font-weight: 800;
        text-align: center;
        border: none;
        padding: 0;
        margin: 0;
    }

    #unread-count h6{
        font-size: 14px;
        line-height: 18px;
        text-transform: uppercase;
        margin: 0;
        font-weight: 800;
        color: #586266;
        text-align: center;
        padding: 0 20px;
        border: none;
    }

    #no-properties {
        width: 340px;
        height: 75px;
        margin: 50px auto 20px;
        position: relative;
    }

    #no-properties  h6{
        font-size: 14px;
        line-height: 18px;
        text-transform: uppercase;
        line-height: 21px;
        margin: 0;
        font-weight: 800;
        color: #00b2ce;
        text-align: center;
        padding: 0 20px;
        border: none;
    }

    .v-card__text{
        overflow: scroll;
        max-height: 350px;
    }

</style>
