<template>
    <v-card>
        <v-card-subtitle>
            <span class="icon"></span>Vacancy By Category
            <button @click="deleteDashboard()" class="w-button btn-sm tertiary-btn delete-button" v-show="canDelete">
                <span class="icon"></span>
            </button>
        </v-card-subtitle>
        <v-card-text>
            <div class="table" >
                <div class="table-row">
                    <div class="table-head" style="flex-grow:2">Category</div>
                    <div class="table-head"># Vacant</div>
                    <div class="table-head text-right"> % Leased</div>
                </div>
                <div id="vacancy-holder">
                    <router-link tag="div" :to="'/units/search?category_id=%5B%22' + v.category_id + '%22%5D&statuses=%5B%22Available%22%5D'" class="table-row" v-for="v in vacancies" v-if="v.category" :key="v.category_id">
                        <div class="table-cell" style="flex-grow:2">
                            <span v-if="v.category">{{v.category}}</span>
                            <span v-else>Uncategorized</span>
                        </div>
                        <div class="table-cell">
                            {{v.count}}
                        </div>
                        <div class="table-cell text-right">
                            {{ ( v.count > 0 && v.total > 0 )? Math.round(((v.total - v.count) / v.total ) * 100) : 0 }} %
                        </div>
                    </router-link>

                    <div class="table-row" v-show="property_id && !vacancies.length">
                        <div class="table-cell">There are no vacancies found</div>
                    </div>
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>
<script type="text/babel">
    import api from '../../assets/api.js';
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import moment from 'moment';
    export default {
        name: 'UnreadCount',
        data: function(){
            return {
                pid: '',
                vacancies: []
            }
        },
        props:['property_id', 'canDelete', 'property', 'dashboard'],
        mounted(){
            this.pid = this.property_id;
            this.fetchData();
        },
        components:{
            Loader,
            Status
        },
        computed:{
        },
        filters:{
            formatDate(date){
                return moment(date).format('MM/DD/YYYY');
            }
        },
        methods:{
            deleteDashboard(){
                api.delete(this, api.DASHBOARD, this.dashboard.id).then(r => {
                    this.$parent.$emit('HBGet:Dashboards'); //this.fetchDashboards();
                });
            },
            fetchData(){
                var data = {
                    property_id: this.pid || ''
                }
                // this.startLoading(this.$options.name);
                api.get(this, api.DASHBOARD_VACANCY_BY_CATEGORY, data).then(r => {
                    this.vacancies = r.vacancies;
                })
            },

        },
        watch:{
            property_id:function (property_id, old_id){
                this.vacancies = [];
                this.pid = property_id;
                this.fetchData();
            }
        }

    }
</script>
<style scoped>
    h4{
        margin: 0 0 20px 0;
    }

    .dashboard-panel-row-2{
        height: 315px;

    }

    #vacancy-holder{
        overflow-y:scroll;
        height: 322px;
    }


    @media (max-width: 1199px){
        .dashboard-panel-row-2{
            height: 415px;

        }
    }

</style>
