<template>
    <v-card>
        <v-card-subtitle>
            <span class="icon"></span>Avg Length Of Stay
            <button @click="deleteDashboard()" class="w-button btn-sm tertiary-btn delete-button" v-show="canDelete">
                <span class="icon"></span>
            </button>
        </v-card-subtitle>
        <v-card-text>
            <div class="table" >
                <div class="table-row">
                    <div class="table-head" style="flex-basis: 50%;">Property</div>
                    <div class="table-head" style="flex-basis: 25%;">Num. Leases</div>
                    <div class="table-head" style="flex-basis: 25%;">Avg. Length</div>
                </div>
                <div id="scroll-holder">
                    <div class="table-row" v-for="property in properties" v-if="property.address" :key="property.address">
                        <div class="table-cell" style="flex-basis: 50%;">{{property.address}}</div>
                        <div class="table-cell" style="flex-basis: 25%;">{{property.num_leases}}</div>
                        <div class="table-cell" style="flex-basis: 25%;">{{ Math.round(property.avg_months * 1e2 )/ 1e2 }} Months</div>
                    </div>
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>
<script type="text/babel">
    import api from '../../assets/api.js';
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import moment from 'moment';
    import { tableKeys as avgLeaseLength } from '../../mixins/tableColumns/avg_lease_length.js';

    export default {
        name: 'AvgLeaseLength',
        data: function(){
            return {
                properties: [],
            }
        },
        mounted(){
            this.pid = this.property_id;
            this.fetchData();
        },
        components:{
            Loader

        },
        computed:{

        },
        filters:{
        },
        methods:{
            deleteDashboard(){
                api.delete(this, api.DASHBOARD, this.dashboard.id).then(r => {
                    this.$parent.$emit('HBGet:Dashboards'); //this.fetchDashboards();
                });
            },
            fetchData(){
                this.startLoading(this.$options.name);

                var data = {
                    filters: avgLeaseLength.data().filters,
                    type: 'AvgLeaseLength',
                    format: 'dashboard'
                }

                api.post(this, api.REPORTS + 'generate', data).then(response => {
                    this.properties = response.properties
                });
            },
            viewDetail(timeperiod){
                switch (timeperiod){
                    case '30':
                        this.$router.push('/billing/aging-detail?end_date=' + moment().subtract(1, 'days').format('YYYY-MM-DD') + '&start_date=' + moment().subtract(30, 'days').format( 'YYYY-MM-DDTHH:mm:ssz'));
                        break;
                    case '90':
                        this.$router.push('/billing/aging-detail?start_date=' + moment().subtract(90, 'days').format('YYYY-MM-DD') + '&end_date=' + moment().subtract(31, 'days').format( 'YYYY-MM-DDTHH:mm:ssz'));
                        break;
                    case '90+':

                        this.$router.push('/billing/aging-detail?end_date=' + moment().subtract(91, 'days').format('YYYY-MM-DDTHH:mm:ssz'));
                        break;
                }
            }
        },
        watch:{
            property_id:function (property_id, old_id){
                this.thirty = [];
                this.ninety = [],
                        this.overNinety = []
                this.pid = property_id;
                this.fetchData();
            }
        },
        props:['property_id', 'canDelete', 'property', 'dashboard']

    }
</script>
<style scoped>
    h4{
        margin: 0 0 20px 0;
    }

    .row.total{
        margin-top: 25px;
        border-top: 1px solid #e2e2e2;


    }
    .dashboard-panel-row-2{
        height: 315px;
    }


    #scroll-holder{
        overflow-y:scroll;
        height: 322px;
    }


</style>
