<template>
    <v-card-text class="pa-0">
        <v-data-table 
            fixed-header 
            disable-pagination 
            hide-default-footer 
            :headers="payload.data.headers" 
            :items="payload.data.items"
            height="250px"
        ></v-data-table>
    </v-card-text>
</template>
<script>
export default {
    name:'DataTable',
    data() {
        return {
        }
    },
    props:[
        'payload'
    ]
}
</script>
<style lang="scss" scoped>

</style>