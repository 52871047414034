<template>
   <div class="container">

       <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>

       <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>


       <div class="row">
           <div class="col-xs-12">
                <h4><i class="fa fa-bolt"></i> Action Items</h4>









            </div>
        </div>



    </div>
</template>
<script type="text/babel">


    import api from '../../assets/api.js';
    import DropdownConfirmation from '../assets/DropdownConfirmation.vue';
    import Datepicker from 'vuejs-datepicker';
    import Modal from '../assets/Modal.vue';
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import moment from 'moment';

    export default {
        name: "ActionItems",
        data() {
            return {
                invoices_mailed: [],
                unpaid_invoices: [],
                sendInvoices: false,
                reviewBillDate: '',
                sendBillDate: '',
                disabled:{
                    to:  new Date(2017, 8,  28),
                    from:   new Date(2017, 9,  28)
                }
            }
        },
        created(){
            this.disabled = {
                to: moment(new Date()).subtract(1, 'day').toDate(),
                from:  moment(new Date()).startOf('day').subtract(1, 'day').add(1, 'month').toDate()
            }
            this.fetchData();
        },
        components:{
            DropdownConfirmation,
            Modal,
            Datepicker,
            Status,
            Loader
        },
        methods: {
            fetchData(){
                api.get(this, api.DASHBOARD_ACTION_ITEMS).then(r => {
                    this.invoices_mailed = r.invoices_mailed;
                    this.unpaid_invoices = r.unpaid_invoices;
                })
            },
            reviewInvoices(){

                if(!this.reviewBillDate){
                    this.errorSet(this.$options.name, 'Please choose a billing date');
                } else {
                    api.post(this, api.SEND_INVOICE_REVIEW, {date: this.reviewBillDate}).then(r => {
                        this.successSet(this.$options.name, 'Invoice Review Sent to your email address')
                    })

                }


            },
            emailInvoices(){

                if(!this.sendBillDate){
                    this.errorSet(this.$options.name, 'Please choose a billing date');
                } else {
                    api.post(this, api.SEND_INVOICES_TO_TENANTS, {date: this.sendBillDate}).then(r => {
                        this.successSet(this.$options.name, 'Invoices have been queued to send to your tenants');
                    }).finally(() =>{
                        this.sendInvoices = false;
                    })

                }
            }
        }
    }
</script>
<style scoped>

    h4{
        margin: 0 0 20px 0;
    }

    .media-icon{
        float: left;
        width: 30px;
    }

    .media-content{
        float: left;
        left: 30px;
    }

</style>